import { css } from '@linaria/core'
import { colors } from '@quipu/style-foundations'

export const container = css`
  display: flex;
`

export const button = css`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`

export const separator = css`
  width: 0.1rem;
  height: 100%;
  background: ${colors.black85};
`

export const trigger = css`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  cursor: pointer;
`

export const disabledOpacity = css`
  opacity: 0.25;
`
