import React from 'react'

import { ProjectFormValues, ProjectFormContent } from './ProjectFormContent'
import { useMutation, useQuery } from '@quipu/apollo'
import {
  DeleteProjectDocument,
  GetProjectDetailsDocument,
  GetProjectsDocument,
  UpdateProjectDocument,
} from '@quipu/graphql'
import { Form, Modal, useForm, yup, yupResolver } from '@quipu/components'
import { useNavigate } from 'react-router-dom'
import { Routes } from '@quipu/modules-common'
import moment from 'moment'

const schema = yup.object().shape({
  name: yup.string().required(),
  date: yup.string().required(),
})

interface UpdateProjectFormProps {
  id: string
  dismissModal: () => void
}

export const UpdateProjectForm = ({
  id,
  dismissModal,
}: UpdateProjectFormProps) => {
  // TODO: Replace this localStorage.getItem with a custom one
  const activeWorkspaceId = localStorage.getItem('activeWorkspace')!

  const navigate = useNavigate()

  const { data, loading } = useQuery(GetProjectDetailsDocument, {
    variables: { id },
    // TODO: Investigate why this is needed. It should read from cache at some
    // point
    fetchPolicy: 'cache-and-network',
  })
  const project = data?.project
  console.log(id, data)

  const [updateProject] = useMutation(UpdateProjectDocument, {
    refetchQueries: [
      {
        query: GetProjectDetailsDocument,
        variables: {
          id,
        },
      },
    ],
    onCompleted: dismissModal,
  })
  const [deleteProject] = useMutation(DeleteProjectDocument, {
    variables: {
      id,
    },
    refetchQueries: [
      {
        query: GetProjectsDocument,
        variables: {
          workspaceId: activeWorkspaceId,
        },
      },
    ],
    onCompleted: () => {
      navigate(Routes.routes.ROOT.create({}))
      dismissModal()
    },
  })

  const getInitialFormValues = () => {
    const tags = project?.tags?.map(({ id }) => id)

    // Let's support only one file for now
    // TODO: Implement this
    // const files = project?.files?.map((file) => ({
    //   id: file.id,
    //   name: file.name,
    //   url: file.url,
    //   ext: file.mimetype,
    // }))

    return {
      name: project?.name!,
      categoryId: project?.category?.id || undefined,
      tags,
      date: project?.date!,
      description: project?.description || null,
    }
  }

  // Handles
  const handleSubmit = async ({ date, ...values }: ProjectFormValues) => {
    updateProject({
      variables: {
        id,
        input: { ...values, date: moment(date).format('YYYY-MM-DD HH:mm:ss') },
      },
    })
  }

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: getInitialFormValues(),
  })

  return (
    <Modal.Card>
      <Form {...methods} onSubmit={handleSubmit}>
        <Modal.PaddedContent all>
          <Modal.Title>Project Details</Modal.Title>

          {loading ? <>Loading...</> : <ProjectFormContent />}
        </Modal.PaddedContent>

        <Modal.Footer>
          <Modal.Submit
            type="button"
            variant="destructive"
            onClick={() => deleteProject()}
          >
            Delete
          </Modal.Submit>
          <Modal.Close />
          <Modal.Submit>Update</Modal.Submit>
        </Modal.Footer>
      </Form>
    </Modal.Card>
  )
}
