import { css } from '@linaria/core'
import { colors } from '@quipu/style-foundations/src'

export const baseStyles = css`
  font-weight: 500;
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  padding: 0.6rem 1rem;
  border-radius: 1.4rem;
`

const primary = css`
  background: ${colors.black};
  color: ${colors.white};
`

const secondary = css`
  background: ${colors.black55};
  color: ${colors.white};
`

const tertiary = css`
  border: 1px solid ${colors.black55};
  color: ${colors.black55};
`

export const VARIANT_STYLES = { primary, secondary, tertiary }
