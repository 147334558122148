import React from 'react'
import { Button, DatePicker, Dropdown } from '@quipu/components'
import { SearchMenuProps } from './Filters.types'
import { DropdownDefault } from '@quipu/components/src/components/Dropdown/presets/Dropdown.default'

export const CommonFilters = ({
  filters,
  setFilters,
  updateSearchValue,
  filterComponents,
  onFilterChange,
  onInnerFilterComponentMenuOpenChange,
}: SearchMenuProps) => {
  const arr =
    filterComponents?.({ filters, setFilters, updateSearchValue })?.map(
      filter => {
        switch (filter.type) {
          case 'button': {
            return (
              <Button
                key={`filter-${filter.name}`}
                size="small"
                variant="tertiary"
                // icon={filter.icon}
                data-state={filter.value ? 'active' : 'inactive'}
                onClick={() => onFilterChange(filter.name as any, filter.value)}
              >
                {filter.displayName}
              </Button>
            )
          }

          case 'choice': {
            return (
              <DropdownDefault
                key={`filter-${filter.name}`}
                trigger={filter.displayName}
                onOpenChange={onInnerFilterComponentMenuOpenChange}
              >
                {filter
                  .options({ filters, setFilters, updateSearchValue })
                  .map(item => (
                    <Dropdown.Item
                      key={`${filter.name}-${item.value}`}
                      onClick={() => {
                        onFilterChange(filter.name as any, item.value)
                      }}
                    >
                      {item.text}
                    </Dropdown.Item>
                  ))}
              </DropdownDefault>
            )
          }
        }
      }
    ) || []

  return (
    <>
      <DatePicker
        key={`filter-date-range`}
        placeholderText="Date"
        selectsRange
        startDate={filters?.date?.[0]}
        endDate={filters?.date?.[1]}
        onCalendarOpen={() => onInnerFilterComponentMenuOpenChange(true)}
        onCalendarClose={() => onInnerFilterComponentMenuOpenChange(false)}
        onChange={(value: [Date | null, Date | null]) =>
          onFilterChange('date', value)
        }
        // TODO: Review these extra props
        excludeScrollbar={true}
        onSelect={() => {}}
      />
      {arr}
    </>
  )
}
