import { useState, useRef, useEffect, useMemo, useCallback } from 'react'
import moment from 'moment'
import { debounce } from 'debounce'

import { BaseFiltersType } from './Filters.types'
import { useBreakpoint } from '@quipu/style-foundations'
import { useOnClickOutside } from '@quipu/utils'

const FILTERS_WITH_VALUES_REGEX = /\b@\w+:\S+\b/g
const FILTERS_WITHOUT_VALUE_REGEX = /(?:\b@\w+:\S+\b|\s)+/g

type DateRange = [string, string]

interface FilterControlsProps {
  onSubmit: (filters: BaseFiltersType) => void
}

export const useFilterControls = <F extends BaseFiltersType>({
  onSubmit,
}: FilterControlsProps) => {
  const isMobile = !useBreakpoint('sm')
  const [filterMenuOpen, setFilterMenuOpen] = useState(false)

  // Used to know when filter components like Datepicker and Dropdown have their
  // menu opened
  const [innerFilterComponentMenuOpen, setInnerFilterComponentMenuOpen] =
    useState(false)

  const [filters, setFilters] = useState({} as F)
  const [searchValue, setSearchValue] = useState('')

  // Refs
  const searchInputRef = useRef<HTMLInputElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  // Menu handlers
  const handleMenuOpen = () => {
    setFilterMenuOpen(true)

    searchInputRef.current?.focus()
  }

  const handleMenuClose = () => {
    setFilterMenuOpen(false)
    searchInputRef.current?.blur()
  }

  // Close menu when clicking outside
  useOnClickOutside(containerRef, () => {
    if (!innerFilterComponentMenuOpen && !isMobile) handleMenuClose()
  })

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      // Open search when pressing cmd + k keys
      if (event.code === 'KeyK' && event.metaKey) {
        handleMenuOpen()
        return
      }

      // Close menu when Escape is pressed
      if (event.code === 'Escape') handleMenuClose()
    }

    document.addEventListener('keydown', listener)

    return () => {
      document.removeEventListener('keydown', listener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Form submit
  const submit = useCallback(onSubmit, [onSubmit])

  const debouncedFormSubmit = useMemo(() => debounce(submit, 500), [submit])

  /**
   * Update the search value including the multiple filters.
   */
  const updateSearchValue = <T extends keyof F>(
    name: T,
    value: NonNullable<F[T]>
  ) => {
    let newSearchValue = searchValue

    if (name in filters) {
      // Filter exists, let's remove it from the string
      if (name === 'date') {
        const DATE_BEFORE_WITH_VALUE_REGEX = /(@from|@to):(\w*-\w*)*/g
        newSearchValue = newSearchValue.replace(
          DATE_BEFORE_WITH_VALUE_REGEX,
          ''
        )
      } else {
        const FILTER_WITH_VALUE_REGEX = new RegExp(`@${String(name)}:\\w*`)
        // Check if the value is different, if so update it
        if (filters[name] === value) {
          newSearchValue = newSearchValue.replace(FILTER_WITH_VALUE_REGEX, '')
        } else {
          // Filter value is the same, let's remove it
          newSearchValue = newSearchValue.replace(
            FILTER_WITH_VALUE_REGEX,
            `@${String(name)}:${value}`
          )
        }
      }
    }

    // Only append boolean filters if the boolean value is true
    if (typeof value === 'boolean') {
      // For booleans make sure the value is true otherwise remove it
      if (value === true) {
        newSearchValue += `${newSearchValue && ' '}@${String(name)}:${value}`
      }
      setSearchValue(newSearchValue)
      return
    }

    // Append before/ after for dates
    if (name === 'date') {
      const dateValues = value as DateRange

      let formattedValue = `${newSearchValue && ' '}@from:${dateValues?.[0]}`
      if (dateValues?.[1]) {
        formattedValue += ` @to:${dateValues?.[1]}`
      }

      setSearchValue(formattedValue)
      return
    }

    if (typeof value !== 'boolean') {
      newSearchValue += `${newSearchValue && ' '}@${String(name)}:${value}`
      setSearchValue(newSearchValue)
      return
    }
  }

  const updateFiltersAccordingToSearchValue = (value: string) => {
    const capturedFilters = value.match(FILTERS_WITH_VALUES_REGEX)
    console.log(capturedFilters)
    const newFilters: F =
      capturedFilters?.reduce((acc, curr) => {
        const [name, val] = curr.split(':')

        // For booleans
        // Check the type of the filter, for booleans grab the value on state
        // because the user can be typing words like "tru" can be are not valid
        const currentFilterValue = (filters as any)?.[name]
        if (typeof currentFilterValue === 'boolean') {
          return { ...acc, [name]: currentFilterValue }
        }

        // For dates
        if (name === 'from') {
          return { ...acc, date: [val] }
        }

        if (name === 'to') {
          return { ...acc, date: [filters.date?.[0], filters.date?.[1]] }
        }

        // For other filters just include filter name and value
        return { ...acc, [name]: val }
      }, {} as F) || ({} as F)

    // Exclude all selectable filters and grab only the "name" filter value
    const strValue: string = (value as string) || ''
    const nameFilterValue = strValue.replace(FILTERS_WITHOUT_VALUE_REGEX, '')

    setFilters({ ...newFilters, name: nameFilterValue.trim() })
  }

  const handleFilterChange = <T extends keyof F>(
    name: T,
    value: NonNullable<F[T]>
  ) => {
    switch (name) {
      case 'name': {
        setSearchValue(value as unknown as string)
        updateFiltersAccordingToSearchValue(value as unknown as string)
        break
      }
      case 'date': {
        const [startDate, endDate] = value as DateRange
        const formattedDates = [
          startDate
            ? moment.utc(startDate as string).format('DD-MM-yyyy')
            : null,
          endDate ? moment.utc(endDate as string).format('DD-MM-yyyy') : null,
        ]
        setFilters({
          ...filters,
          date: value as DateRange,
        })
        updateSearchValue(name, formattedDates as any)
        break
      }
    }
  }

  const previousFiltersRef = useRef({} as F)

  useEffect(() => {
    if (
      JSON.stringify(filters) !== JSON.stringify(previousFiltersRef.current)
    ) {
      previousFiltersRef.current = filters // Update the ref

      if (Object.keys(filters).length) {
        // Call the debounced function
        debouncedFormSubmit(filters)
      }
    }
  }, [filters, debouncedFormSubmit])

  const handleClearFilters = () => {
    setFilters({} as F)
    setSearchValue('')
    handleMenuClose()
    debouncedFormSubmit({})
  }

  return {
    containerRef,

    searchInputRef,
    searchValue,

    filters,
    handleFilterChange,
    setFilters,
    updateSearchValue,
    handleClearFilters,

    canShowMobileMenu: isMobile && filterMenuOpen,
    filterMenuOpen,
    handleMenuOpen,
    handleMenuClose,

    setInnerFilterComponentMenuOpen,
  }
}
