import React, { useEffect } from 'react'
import moment from 'moment'
import {
  Modal,
  useForm,
  Form,
  yupResolver,
  Button,
  cs,
} from '@quipu/components'
import {
  TransactionForm,
  TransactionFormValues,
  transactionFormSchema,
} from './TransactionForm'
import { useMutation, useQuery } from '@quipu/apollo'
import { UpdateExpenseDocument } from '@quipu/graphql'
import {
  GetTransactionDocument,
  TransactionType,
} from '@quipu/graphql/src/typemapImports'
import * as styles from './UpdateTransactionModal.styles'

interface UpdateTransactionModalProps {
  id: string
  dismissModal: () => void
}

export const UpdateTransactionModal = ({
  id,
  dismissModal,
}: UpdateTransactionModalProps) => {
  const { data: expenseData, loading } = useQuery(GetTransactionDocument, {
    variables: {
      id,
    },
  })
  const transaction = expenseData?.transaction

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(transactionFormSchema),
    defaultValues: {},
  })

  const { reset } = methods

  useEffect(() => {
    if (transaction) {
      const tags = transaction?.tags?.map(({ id }: { id: string }) => id) || []

      // Let's support only one file for now
      const files = transaction?.files?.map(
        (file: {
          id: string
          name: string
          url: string
          mimetype: string
        }) => ({
          id: file.id,
          name: file.name,
          url: file.url,
          ext: file.mimetype,
        })
      )

      const initialValues = {
        type: transaction?.type!,
        name: transaction?.name!,
        categoryId: transaction?.category?.id || undefined,
        tags,
        amount: transaction!.amount.toFixed(2),
        date: moment(transaction?.date || undefined).format(
          'YYYY-MM-DD HH:mm:ss'
        ),
        notes: transaction?.notes || '',
        files: (files || []) as any,
      }

      reset(initialValues)
    }
  }, [transaction, reset])

  const [updateExpense] = useMutation(UpdateExpenseDocument)

  const handleSubmit = async ({
    amount,
    files,
    type,
    date,
    ...values
  }: TransactionFormValues) => {
    const filesMapped = files?.map(file => file.id)
    const commonFormData = {
      ...values,
      amount: parseFloat(amount || '0'),
      files: filesMapped,
      type: type as TransactionType,
      date: moment(date).format('YYYY-MM-DD HH:mm:ss'),
    }

    updateExpense({
      variables: {
        id,
        input: { ...commonFormData, draft: expenseData?.transaction.draft },
      },
      onCompleted: dismissModal,
    })
  }

  const handleDraftConversion = () => {
    if (!expenseData?.transaction) return

    const { type, amount, date, name, category } = expenseData.transaction
    updateExpense({
      variables: {
        id,
        input: {
          // FIXME: There is a BE issue where if we don't pass all fields in
          // the mutation it removes the existent values
          amount,
          date,
          name,
          type,
          draft: !expenseData.transaction.draft,
          categoryId: category?.id,
        },
      },
    })
  }

  if (loading) return null

  return (
    <Modal.Card>
      <Form {...methods} onSubmit={handleSubmit}>
        <Modal.PaddedContent all>
          <Modal.Title>Update Transaction</Modal.Title>

          <TransactionForm {...methods} />
        </Modal.PaddedContent>

        <Modal.Footer>
          <Button
            variant="secondary"
            className={cs(styles.alignLeft)}
            onClick={handleDraftConversion}
          >
            Convert to{' '}
            {expenseData?.transaction.draft ? 'Transaction' : 'Draft'}
          </Button>
          <Modal.Close />
          <Modal.Submit>Update Transaction</Modal.Submit>
        </Modal.Footer>
      </Form>
    </Modal.Card>
  )
}
