import React from 'react'

import { SearchMenuProps } from './Filters.types'
import * as s from './Filters.styles'
import { CommonFilters } from './CommonFilters'

export const DesktopMenu = ({
  filters,
  setFilters,
  updateSearchValue,
  filterComponents,
  onFilterChange,
  onInnerFilterComponentMenuOpenChange,
}: SearchMenuProps) => (
  <s.Menu>
    <CommonFilters
      {...{
        filters,
        setFilters,
        updateSearchValue,
        filterComponents,
        onFilterChange,
        onInnerFilterComponentMenuOpenChange,
      }}
    />
  </s.Menu>
)
