import React, { FC } from 'react'

import * as s from './Filters.styles'
import { useFilterControls } from './useFilterControls'
import { SearchInput } from '@quipu/components'
import { BaseFiltersType, FiltersProps } from './Filters.types'
// import { MobileMenu } from './MobileMenu'
import { DesktopMenu } from './DesktopMenu'

export const Filters: FC<FiltersProps> = ({
  filterComponents,
  onChange,
  onSubmit,
}) => {
  const {
    containerRef,

    searchInputRef,
    searchValue,

    filters,
    handleFilterChange,
    setFilters,
    updateSearchValue,
    // handleClearFilters,

    canShowMobileMenu,
    filterMenuOpen,
    handleMenuOpen,
    // handleMenuClose,

    setInnerFilterComponentMenuOpen,
  } = useFilterControls<BaseFiltersType>({ onSubmit })

  const handleChange = <T extends keyof BaseFiltersType>(
    name: T,
    value: NonNullable<BaseFiltersType[T]>
  ) => {
    // In case of one of the default filter, useFilterControls will take
    // care of that
    if (name === 'name' || name === 'date') {
      handleFilterChange(name, value)
      return
    }

    onChange?.(name, value, { filters, setFilters, updateSearchValue })
  }

  if (canShowMobileMenu) {
    // TODO: Implement Mobile menu
    return null
    // return (
    //   <MobileMenu
    //     {...{
    //       filters,
    //       setFilters,
    //       updateSearchValue,
    //       searchInputRef,
    //       searchValue,
    //       filterComponents,
    //     }}
    //     onFilterChange={handleChange}
    //     onDatePickerOpenChange={setDatePickerOpen}
    //     onClearClick={handleClearFilters}
    //     onCloseClick={handleMenuClose}
    //     dismissModal={() => {}}
    //   />
    // )
  }

  return (
    <div className={s.filtersRelativeContainer}>
      <s.FiltersAbsoluteContainer ref={containerRef} open={filterMenuOpen}>
        <SearchInput
          inputRef={searchInputRef}
          value={searchValue}
          placeholder="Search"
          onFocus={handleMenuOpen}
          onChange={event => {
            handleChange('name', event.target.value)
          }}
        />
        {filterMenuOpen && (
          <DesktopMenu
            {...{ filters, setFilters, updateSearchValue, filterComponents }}
            onFilterChange={handleChange}
            onInnerFilterComponentMenuOpenChange={
              setInnerFilterComponentMenuOpen
            }
          />
        )}
      </s.FiltersAbsoluteContainer>
    </div>
  )
}
