import React from 'react'
import { useParams } from 'react-router-dom'
import { TransactionsList, useTransactionsPagination } from '../../components'
import { useMutation, useQuery } from '@quipu/apollo'
import {
  DeleteExpenseDocument,
  GetProjectDetailsDocument,
  GetProjectTransactionsDocument,
} from '@quipu/graphql'
import {
  BaseFiltersType,
  Filters,
  FiltersState,
} from '../../components/Filters'
import { UpdateTransactionModal } from '../../components/TransactionForm'
import { useModal } from '@quipu/components'
import * as s from './ProjectTransactionsView.styles'
import { formatDateToGQLResolver } from '@quipu/utils'

interface FiltersType extends BaseFiltersType {
  category?: string
  drafts?: boolean
}

export const ProjectTransactionsView = () => {
  const locationParams = useParams<{ projectId: string }>()
  const { showModal } = useModal()

  const {
    data: projectData,
    fetchMore,
    // loading,
    refetch,
  } = useQuery(GetProjectTransactionsDocument, {
    variables: {
      id: locationParams.projectId!,
      pagination: {
        limit: 20,
        page: 1,
      },
    },
  })
  const project = projectData?.project
  const expenses = project?.transactions.entries

  const [deleteExpense] = useMutation(DeleteExpenseDocument, {
    refetchQueries: [
      {
        query: GetProjectDetailsDocument,
        variables: {
          id: locationParams.projectId!,
        },
      },
      {
        query: GetProjectTransactionsDocument,
        variables: {
          id: locationParams.projectId!,
          pagination: {
            limit: 20,
            page: 1,
          },
        },
      },
    ],
  })

  const { placeholders } = useTransactionsPagination({
    data: expenses || [],
    fetchMore,
    page: project?.transactions.pagination?.page! + 1,
    totalCount: project?.transactions.pagination?.total,
  })

  const handleDelete = (id: string) => {
    deleteExpense({
      variables: {
        id,
      },
    })
  }

  const handleFilterChange = <T extends keyof FiltersType>(
    name: T,
    value: NonNullable<FiltersType[T]>,
    { filters, setFilters, updateSearchValue }: FiltersState
  ) => {
    switch (name) {
      // case 'category': {
      //   setFilters({ ...filters, category: value as string })
      //   updateSearchValue(name as any, value)
      //   break
      // }

      default: {
        setFilters({ ...filters, [name]: value })
        updateSearchValue(name as any, value)
      }
    }
  }

  const handleSearch = async (filters: FiltersType) => {
    const parsedFilters: {
      date?: { startDate: string; endDate: string }
    } = {}

    if (filters.date?.[0] && filters.date?.[1]) {
      parsedFilters.date = {
        startDate: formatDateToGQLResolver(filters.date[0]),
        endDate: formatDateToGQLResolver(filters.date[1]),
      }
    }

    refetch({
      filters: {
        name: filters.name,
        // categoryId: filters.category,
        drafts: filters.drafts,
        ...parsedFilters,
      },
    })
  }

  return (
    <>
      <div className={s.filterContainer}>
        <Filters
          filterComponents={() => {
            return [
              {
                type: 'choice',
                name: 'drafts',
                displayName: 'Drafts',
                options: () => [
                  {
                    text: 'Drafts only',
                    value: true,
                  },
                  {
                    text: 'Exclude drafts',
                    value: false,
                  },
                ],
              },
            ]
          }}
          onChange={handleFilterChange}
          onSubmit={handleSearch}
        />
      </div>

      <TransactionsList
        items={expenses}
        canPinExpenses={false}
        onClick={item => {
          const dismissModal = showModal({
            element: (
              <UpdateTransactionModal
                id={item?.id}
                dismissModal={() => dismissModal()}
              />
            ),
          })
        }}
        onDeleteClick={handleDelete}
        placeholders={placeholders}
      />
    </>
  )
}
