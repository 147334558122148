import React from 'react'
import { Layout } from '../Layout'
import * as styles from './ProjectLayout.styles'
import { Button, cs, Tabs, useModal } from '@quipu/components'
import { ProjectInfo } from '../ProjectInfo'
import { useQuery } from '@quipu/apollo'
import { GetProjectDetailsDocument } from '@quipu/graphql'
import { matchPath, Outlet, useNavigate, useParams } from 'react-router-dom'
import { ProjectTotals } from '../ProjectTotals'
import { Routes } from '@quipu/modules-common'
import { CreateTransactionModal } from '../TransactionForm'

export const ProjectLayout = () => {
  const workspaceId = localStorage.getItem('activeWorkspace')!
  const locationParams = useParams<{ projectId: string }>()
  const navigate = useNavigate()
  const { showModal } = useModal()

  const { data: projectData, refetch } = useQuery(GetProjectDetailsDocument, {
    variables: {
      id: locationParams.projectId!,
    },
    // TODO: Investigate why this is needed. It should read from cache at some
    // point
    fetchPolicy: 'cache-and-network',
  })

  const totalIncome = projectData?.project.transactions.totals.income || 0
  const totalExpense = projectData?.project.transactions.totals.expense ?? 0

  const handleAddTransaction = () => {
    const dismissModal = showModal({
      element: (
        <CreateTransactionModal
          projectId={locationParams.projectId!}
          dismissModal={() => dismissModal()}
        />
      ),
    })
  }

  const handleProjectTotalsFilter = (value?: [string, string]) => {
    refetch({
      filters: {
        date: value
          ? {
              startDate: value[0],
              endDate: value[1],
            }
          : undefined,
      },
    })
  }

  const renderLayoutContent = () => (
    <>
      <div className={styles.projectInfoContainer}>
        <ProjectInfo
          id={projectData!.project.id!}
          name={projectData!.project.name!}
          category={projectData!.project.category}
        />

        <Button onClick={handleAddTransaction}>Add transaction</Button>
      </div>

      <div className={cs.global('mt-52')}>
        <ProjectTotals
          totalExpense={totalExpense}
          totalIncome={totalIncome}
          onChange={handleProjectTotalsFilter}
        />
      </div>

      <div className={cs.global('mt-52', 'mb-48')}>
        <Tabs
          tabs={[
            {
              name: 'Transactions',
              path: Routes.routes.PROJECT_DETAILS.create({
                workspaceId,
                projectId: locationParams.projectId!,
              }),
              active: Boolean(
                matchPath(
                  location.pathname,
                  Routes.routes.PROJECT_DETAILS.create({
                    workspaceId,
                    projectId: locationParams.projectId!,
                  })
                )
              ),
            },
            {
              name: 'Budget',
              path: Routes.routes.PROJECT_BUDGET.create({
                workspaceId,
                projectId: locationParams.projectId!,
              }),
              active: Boolean(
                matchPath(
                  location.pathname,
                  Routes.routes.PROJECT_BUDGET.create({
                    workspaceId,
                    projectId: locationParams.projectId!,
                  })
                )
              ),
            },
          ]}
          onClick={navigate}
        />
      </div>

      <Outlet />
    </>
  )

  return (
    <Layout>{!projectData ? <>Loading...</> : renderLayoutContent()}</Layout>
  )
}
